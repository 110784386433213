import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import groupFilter from "@/store/modules/reservations/filters/groupFilter";
import statusFilter from "@/store/modules/reservations/filters/statusFilter";
import skuFilter from "@/store/modules/reservations/filters/skuFilter";
import idFilter from "@/store/modules/reservations/filters/idFilter";
import emailFilter from "@/store/modules/reservations/filters/emailFilter";
import locationFilter from "@/store/modules/reservations/filters/locationFilter";
import groups from "@/store/modules/reservations/groups";

const reservationOverview = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    reservation: null
  },
  modules: {
    idFilter,
    skuFilter,
    groupFilter,
    statusFilter,
    emailFilter,
    locationFilter,
    groups
  },
  mutations: {
    ...tableMutations,
    SET_RESERVATION(state, reservation) {
      state.reservation = reservation;
    }
  },
  actions: {
    ...tableActions,
    fetchItems({ state, commit }) {
      commit("SET_LOADING", true);

      const params = {
        pagination: state.pagination,
        sorting: state.sorting,
        search: state.search
      };

      if (this.requestController) {
        this.requestController.abort();
      }
      this.requestController = new AbortController();

      InventoryService.reservations.list(params, { signal: this.requestController.signal })
        .then(json => {
          if (json) {
            commit("SET_ITEMS", json);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },
    selectReservation({commit}, item) {
      if (item !== null) {
        commit("SET_RESERVATION", item);
        commit("SET_ITEM", item);
      } else {
        commit("SET_RESERVATION", null);
        commit("SET_ITEM", null);
      }
    },
  },
  getters: {
    ...tableGetters
  }
};

export default reservationOverview;
