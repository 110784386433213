import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/default',
    name: 'default',
    redirect: "/sku"
  },
  {
    path: "/sku",
    name: "sku",
    menuTitle: i18n.t("skus.title"),
    menuIcon: "mdi-information-outline",
    showIcon: false,
    component: () => import("@/views/SkusView"),
    active: true
  },
  {
    path: "/",
    name: "loading",
    component: () => import("@/views/LoadingView.vue"),
    active: true
  },
  {
    path: "/sku/:sku",
    name: "sku/detail",
    component: () => import("@/views/SkusView"),
    active: true
  },
  {
    path: "/expected-stock",
    name: "expected-stock",
    menuTitle: i18n.t("expected-stock.title"),
    menuIcon: "mdi-store",
    showIcon: false,
    component: () => import("@/views/ExpectedStockView"),
    active: true
  },
  {
    path: "/locations",
    name: "locations",
    menuTitle: i18n.t("locations.title"),
    menuIcon: "mdi-store",
    showIcon: false,
    component: () => import("@/views/LocationsView"),
    active: true
  },
  {
    path: "/virtual-stocks",
    name: "virtualStockOverview",
    menuTitle: i18n.t("virtuals.title"),
    menuIcon: "mdi-filter-outline",
    showIcon: false,
    component: () => import("@/views/VirtualsView"),
    active: true
  },
  {
    path: "/adapters",
    name: "adapters",
    menuTitle: i18n.t("adapters.title"),
    menuIcon: "mdi-database-sync",
    showIcon: false,
    component: () => import("@/views/AdaptersView"),
    active: true
  },
  {
    path: "/online-reservations",
    name: "online-reservations",
    menuTitle: i18n.t("online-reservations.title"),
    menuIcon: "mdi-calendar-lock",
    showIcon: false,
    component: () => import("@/views/OnlineReservationsView"),
    active: true
  },
  {
    path: "/store-reservations",
    name: "store-reservations",
    menuTitle: i18n.t("store-reservations.title"),
    menuIcon: "mdi-calendar-lock",
    showIcon: false,
    component: () => import("@/views/StoreReservationsView.vue"),
    active: true
  },
  {
    path: "/synchronisation",
    name: "synchronisation",
    menuTitle: i18n.t("synchronisation.title"),
    menuIcon: "mdi-sync",
    showIcon: false,
    component: () => import("@/views/SynchronisationLogView"),
    active: true
  },
  {
    path: "/stock-mutations",
    name: "mutations",
    menuTitle: i18n.t("mutations.title"),
    menuIcon: "mdi-database-sync",
    showIcon: false,
    component: () => import("@/views/StockMutationsView"),
    active: true
  },
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeResolve(authGuard);
router.beforeEach((to, from, next) => {
  if (to.path !== '/') {
    if (store.getters["settings/settings"] === null) {
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
